import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "block hover:bg-gray-50"
}
const _hoisted_2 = { class: "px-4 py-4 sm:px-6" }
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = { class: "flex-1" }
const _hoisted_5 = {
  key: 0,
  class: "flex"
}
const _hoisted_6 = { class: "sr-only" }
const _hoisted_7 = { class: "sr-only" }
const _hoisted_8 = { class: "sr-only" }
const _hoisted_9 = { class: "mt-2 sm:flex sm:justify-between" }
const _hoisted_10 = { class: "sm:flex sm:space-x-4" }
const _hoisted_11 = {
  key: 0,
  class: "flex items-center text-sm text-gray-500"
}
const _hoisted_12 = {
  key: 1,
  class: "mt-2 flex items-center text-sm text-gray-500 sm:mt-0"
}
const _hoisted_13 = {
  key: 0,
  class: "mt-2 flex items-center text-sm text-gray-500 sm:mt-0"
}
const _hoisted_14 = ["datetime"]

import { computed, PropType } from "vue";
import router from "@/router";
import { formatDateAsString } from "@/utils/datetimeCommon";
import { UiButton } from "@/components";
import { CalendarIcon, UsersIcon, PhoneIcon } from "@heroicons/vue/solid";
import {
  CheckCircleIcon,
  XCircleIcon,
  PencilAltIcon,
} from "@heroicons/vue/outline";
import { ILevel, Permission } from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'LevelListItem',
  props: {
  modelValue: {
    type: Object as PropType<ILevel>,
    required: true,
  },
},
  emits: ["activate", "inactivate"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const lastModifiedBy = computed(
  () =>
    props.modelValue.updatedByUser || props.modelValue.createdByUser || null,
);

const handleActivateClick = () => {
  emit("activate", props.modelValue);
};

const handleInactivateClick = () => {
  emit("inactivate", props.modelValue);
};

const handleEditClick = () => {
  router.push({
    name: "EditLevelById",
    params: {
      id: props.modelValue.id,
    },
  });
};

return (_ctx: any,_cache: any) => {
  const _directive_tippy = _resolveDirective("tippy")!

  return (props.modelValue)
    ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can(_unref(Permission).VIEW_LEVEL) ? 'RouterLink' : 'span'), {
                to: {
              name: 'ViewLevelById',
              params: {
                id: props.modelValue.id,
              },
            },
                class: "truncate text-sm font-medium text-primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.modelValue.name), 1)
                ]),
                _: 1
              }, 8, ["to"])),
              _createElementVNode("span", {
                class: _normalizeClass([[
              props.modelValue.isActive
                ? 'bg-green-100 text-green-800'
                : 'bg-red-100 text-red-800',
            ], "inline-flex ml-2 rounded-full px-2 text-xs font-semibold leading-5"])
              }, _toDisplayString(props.modelValue.isActive
                ? _ctx.$t("core.options.Active")
                : _ctx.$t("core.options.Inactive")), 3)
            ]),
            (_ctx.$can(_unref(Permission).EDIT_LEVEL))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (props.modelValue.isActive)
                    ? _withDirectives((_openBlock(), _createBlock(_unref(UiButton), {
                        key: 0,
                        size: "",
                        shape: "rounded-full",
                        variant: "inherit",
                        "text-variant": "danger",
                        shadow: "none",
                        themed: "",
                        class: "border-transparent focus:ring-danger shadow-none text-xs",
                        onClick: handleInactivateClick
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("core.actions.inactivate")), 1),
                          _createVNode(_unref(XCircleIcon), {
                            class: "h-6 w-6",
                            "aria-hidden": "true"
                          })
                        ]),
                        _: 1
                      })), [
                        [_directive_tippy, _ctx.$t('core.actions.Inactivate')]
                      ])
                    : _withDirectives((_openBlock(), _createBlock(_unref(UiButton), {
                        key: 1,
                        size: "",
                        shape: "rounded-full",
                        variant: "inherit",
                        "text-variant": "success",
                        shadow: "none",
                        themed: "",
                        class: "border-transparent focus:ring-success shadow-none text-xs",
                        onClick: handleActivateClick
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("core.actions.Activate")), 1),
                          _createVNode(_unref(CheckCircleIcon), {
                            class: "h-6 w-6",
                            "aria-hidden": "true"
                          })
                        ]),
                        _: 1
                      })), [
                        [_directive_tippy, _ctx.$t('core.actions.Activate')]
                      ]),
                  _withDirectives((_openBlock(), _createBlock(_unref(UiButton), {
                    size: "",
                    shape: "rounded-full",
                    variant: "inherit",
                    "text-variant": "primary",
                    shadow: "none",
                    themed: "",
                    class: "border-transparent focus:ring-primary shadow-none ml-1 text-xs",
                    onClick: handleEditClick
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("core.actions.Edit")), 1),
                      _createVNode(_unref(PencilAltIcon), {
                        class: "h-6 w-6",
                        "aria-hidden": "true"
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_tippy, _ctx.$t('core.actions.Edit')]
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              (_ctx.$can(_unref(Permission).VIEW_USER))
                ? (_openBlock(), _createElementBlock("p", _hoisted_11, [
                    _createVNode(_unref(UsersIcon), {
                      class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                      "aria-hidden": "true"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("level.list.usersCount", props.modelValue.users?.length || 0, {
                named: { n: props.modelValue.users?.length },
              })), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.$can(_unref(Permission).VIEW_NUMBER))
                ? (_openBlock(), _createElementBlock("p", _hoisted_12, [
                    _createVNode(_unref(PhoneIcon), {
                      class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                      "aria-hidden": "true"
                    }),
                    _createTextVNode(" " + _toDisplayString(props.modelValue.numbers !== undefined
                ? _ctx.$t(
                    "level.list.phoneNumbersCount",
                    props.modelValue.numbers.length || 0,
                    {
                      named: { n: props.modelValue.numbers.length },
                    },
                  )
                : ""), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            (lastModifiedBy.value != null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createVNode(_unref(CalendarIcon), {
                    class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                    "aria-hidden": "true"
                  }),
                  _createElementVNode("p", null, [
                    _createTextVNode(_toDisplayString(props.modelValue.updatedByUser
                ? _ctx.$t("level.list.updatedAt")
                : _ctx.$t("level.list.createdAt")) + " ", 1),
                    _createElementVNode("time", {
                      datetime: 
                props.modelValue.updatedAt || props.modelValue.createdAt
              
                    }, _toDisplayString(_unref(formatDateAsString)(
                  props.modelValue.updatedAt || props.modelValue.createdAt,
                  "LL",
                )), 9, _hoisted_14),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("level.list.by")) + " ", 1),
                    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can(_unref(Permission).VIEW_USER) ? 'RouterLink' : 'span'), {
                      to: {
                name: 'ViewUserById',
                params: {
                  id: lastModifiedBy.value.id,
                },
              },
                      class: "truncate text-sm font-medium text-primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(lastModifiedBy.value.name), 1)
                      ]),
                      _: 1
                    }, 8, ["to"]))
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})