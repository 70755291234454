import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createSlots as _createSlots, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-3 gap-6" }
const _hoisted_4 = {
  key: 2,
  class: ""
}
const _hoisted_5 = {
  for: "levels",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_6 = {
  key: 3,
  class: ""
}
const _hoisted_7 = {
  for: "roles",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_8 = { class: "" }
const _hoisted_9 = {
  for: "levels",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_10 = { class: "flex flex-col justify-end pb-1.5" }
const _hoisted_11 = { class: "text-base font-semibold leading-6 text-gray-800 mb-4" }
const _hoisted_12 = { class: "grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-6 relative" }
const _hoisted_13 = { class: "flex flex-col justify-between bg-gray-50 text-sm px-4 py-3 absolute rounded bottom-0 mt-6 -right-[215px] shadow text-gray-600 border-r-4 border-b-4 border-cyan-600" }
const _hoisted_14 = {
  key: 0,
  class: "grid grid-cols-2 md:grid-cols-12 gap-4 relative mt-5"
}
const _hoisted_15 = { class: "col-span-3" }
const _hoisted_16 = {
  for: "level",
  class: "block text-sm font-medium leading-6 text-gray-800"
}
const _hoisted_17 = { class: "flex justify-end px-4 sm:px-0" }

import { computed, reactive, Ref, ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useVuelidate, ValidationArgs } from "@vuelidate/core";
import { can } from "@/utils/auth";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
  containsUppercase,
  containsLowercase,
  containsNumber,
  containsSpecial,
} from "@/utils/validators";
import { toast, toastServiceError } from "@/utils/notification";
import {
  UserService,
  LevelService,
  RoleService,
  NumberService,
} from "@/services";
import {
  UiPageHeader,
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiMultiselect,
  UiPanel,
} from "@/components";
import {
  IUser,
  ILevel,
  IRole,
  INumber,
  Permission,
  IDefaultServiceResult,
  IUserServiceCreateResult,
  UiActions,
  IUserFormState,
  FormAction,
  IUserNumberFormState,
  FilterGetters,
} from "@/definitions";

import { AxiosResponse } from "axios";
import { DesktopComputerIcon } from "@heroicons/vue/solid";
import { DeviceMobileIcon } from "@heroicons/vue/outline";
import { Validation } from "@vuelidate/core";
import router from "@/router";
import SVGDeviceExternal from "@/components/svg/SVGDeviceExternal.vue";
import SVGChromeLogo from "@/components/svg/SVGChromeLogo.vue";
import UserFormExtension from "@/views/pages/user/components/UserFormExtension.vue";
import UserFormDevice from "@/views/pages/user/components/UserFormDevice.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props) {

const route = useRoute();
const { t } = useI18n();

const props = __props;

const formState = reactive<IUserFormState>({
  name: "",
  email: "",
  levels: [],
  roles: [],
  isActive: true,
  hasMeeting: false,
  standardLevelId: null,
  devices: {
    mobile: false,
    desktop: false,
    web: false,
    external: false,
  },
  number: null,
  extension: null,
});

const rules = computed<ValidationArgs<IUserFormState>>(() => {
  const r: ValidationArgs<IUserFormState> = {
    name: {
      required,
      maxLength: maxLength(500),
    },
    email: {
      email,
      required,
      maxLength: maxLength(250),
    },
    levels: {
      required,
    },
    number: {},
    roles: {
      required,
    },
    extension: {},
    devices: {
      mobile: false,
      desktop: false,
      web: false,
      external: false,
    },
    isActive: {
      required,
    },
    standardLevelId: {
      required,
    },
    hasMeeting: {},
  };

  if (props.action == FormAction.CREATE) {
    r.password = {
      required,
      minLength: minLength(8),
      maxLength: maxLength(250),
      containsUppercase,
      containsLowercase,
      containsNumber,
      containsSpecial,
    };
    r.confirmPassword = {
      required,
      sameAsPassword: sameAs(formState.password, t("user.labels.password")),
    };
  }

  return r;
});

const v$: Ref<Validation<ValidationArgs<IUserFormState>>> = useVuelidate(
  rules,
  formState,
);
const externalConfig = ref();

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
const levelHasMeeting = levelFilter.value.meeting;

const levels: Ref<ILevel[]> = ref([]);

const roles: Ref<IRole[]> = ref([]);

const noDevice = computed(
  () => Object.values(formState.devices).filter((v) => v).length === 0,
);

const selectedLevels = computed(() =>
  levels.value.filter((lvl) => formState.levels.indexOf(lvl.id) > -1),
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getLevels = () => {
  LevelService.getAll<ILevel[]>({
    params: {
      is_active: true,
    },
  }).then((response) => {
    levels.value = response.data;

    if (!levelFilter.value.root) {
      levels.value = [levelFilter.value];

      formState.levels.push(levelFilter.value.id);
      formState.standardLevelId = levelFilter.value.id;
    }
  }, toastServiceError);
};

if (can(Permission.ASSIGN_LEVEL_TO_USER)) {
  getLevels();
}
watch([levelFilter], () => {
  getLevels();
});

if (can(Permission.ASSIGN_ROLE_TO_USER)) {
  RoleService.getAll<IRole[]>().then((response) => {
    roles.value = response.data;
  }, toastServiceError);
}

if (props.action == FormAction.EDIT) {
  const id = parseInt(route.params.id as string);

  setLoading(true);
  UserService.get<IUser>(id)
    .then((response: AxiosResponse<IUser>) => {
      const {
        name,
        email,
        levels,
        roles,
        isActive,
        standardLevelId,
        hasMeeting,
        number,
        extension,
        devices,
        deviceExternal,
      } = response.data;

      formState.id = id;
      formState.name = name;
      formState.email = email;
      formState.levels = levels?.map((l) => l.id) || [];
      formState.roles = roles?.map((l) => l.id) || [];
      formState.isActive = isActive;
      formState.standardLevelId = standardLevelId ?? null;
      formState.hasMeeting = hasMeeting;
      if (deviceExternal) {
        externalConfig.value = {
          hostname: deviceExternal.hostname,
          sipUser: deviceExternal.sipUser,
          sipPassword: deviceExternal.sipPassword,
        };
      }

      formState.number = number ?? {
        id: null,
        levelId: null,
        numberId: null,
      };
      formState.extension = extension ?? {
        id: null,
        levelId: formState.standardLevelId,
        label: null,
        number: null,
      };
      formState.devices = devices;
    }, toastServiceError)
    .finally(() => setLoading(false));
} else {
  formState.number = {
    id: null,
    levelId: null,
    numberId: null,
  };
  formState.extension = {
    id: null,
    levelId: formState.standardLevelId,
    label: null,
    number: null,
  };
}

const numbersRaw: Ref<INumber[]> = ref([]);
const getNumbers = () => {
  setLoading(true);
  const levels = formState.levels;
  NumberService.getAll<INumber[]>({
    params: {
      levels,
    },
  })
    .then((response: AxiosResponse<INumber[]>) => {
      numbersRaw.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};
getNumbers();

function setFormNumbers() {
  if (formState.number?.levelId !== formState.standardLevelId) {
    formState.number = {
      id: null,
      levelId: formState.standardLevelId,
      numberId: null,
    } as IUserNumberFormState;
  }
}

function getLevelNumbers(levelID: number | null) {
  return numbersRaw.value.filter((number) => number.level.id == levelID);
}

const onSubmit = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  await setLoading(true);

  const handleServiceSuccess = (message: string) => {
    toast.success(message);
    v$.value.$reset();
    router.push({
      name: "ListUser",
    });
  };

  if (props.action == FormAction.EDIT) {
    const id = parseInt(route.params.id as string);
    UserService.update<IDefaultServiceResult, IUserFormState>(id, formState)
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    UserService.create<IUserServiceCreateResult, IUserFormState>(formState)
      .then(
        (response: AxiosResponse<IUserServiceCreateResult>) =>
          handleServiceSuccess(response.data.message),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  }
};

watch(
  () => formState.levels,
  () => {
    getNumbers();
    setFormNumbers();
  },
);

watch(
  () => formState.standardLevelId,
  (newValue) => {
    if (formState.number) {
      formState.number.levelId = newValue;
    }
    if (formState.extension) {
      formState.extension.levelId = newValue;
    }
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UiPageHeader), null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("user." + props.action + ".title")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers(onSubmit, ["prevent"])
    }, [
      _createVNode(_unref(UiPanel), null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(UiTextInput), {
              modelValue: formState.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formState.name) = $event)),
              name: "name",
              type: "text",
              label: _ctx.$t('user.labels.name'),
              class: "",
              errors: _unref(v$).name.$errors,
              required: _unref(v$).name.required !== undefined,
              onBlur: _unref(v$).name.$touch
            }, null, 8, ["modelValue", "label", "errors", "required", "onBlur"]),
            _createVNode(_unref(UiTextInput), {
              modelValue: formState.email,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formState.email) = $event)),
              name: "email",
              type: "text",
              label: _ctx.$t('user.labels.email'),
              class: "",
              autocomplete: "new-email",
              errors: _unref(v$).email.$errors,
              required: _unref(v$).email.required !== undefined,
              onBlur: _unref(v$).email.$touch
            }, null, 8, ["modelValue", "label", "errors", "required", "onBlur"]),
            (props.action == _unref(FormAction).CREATE)
              ? (_openBlock(), _createBlock(_unref(UiTextInput), {
                  key: 0,
                  modelValue: formState.password,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formState.password) = $event)),
                  name: "password",
                  type: "password",
                  label: _ctx.$t('user.labels.password'),
                  class: "",
                  autocomplete: "new-password",
                  errors: _unref(v$).password?.$errors,
                  required: _unref(v$).password.required !== undefined,
                  onBlur: _unref(v$).password.$touch
                }, null, 8, ["modelValue", "label", "errors", "required", "onBlur"]))
              : _createCommentVNode("", true),
            (props.action == _unref(FormAction).CREATE)
              ? (_openBlock(), _createBlock(_unref(UiTextInput), {
                  key: 1,
                  modelValue: formState.confirmPassword,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formState.confirmPassword) = $event)),
                  name: "confirmPassword",
                  type: "password",
                  label: _ctx.$t('user.labels.confirmPassword'),
                  class: "",
                  errors: _unref(v$).confirmPassword?.$errors,
                  required: _unref(v$).confirmPassword.required !== undefined,
                  onBlur: _unref(v$).confirmPassword.$touch
                }, null, 8, ["modelValue", "label", "errors", "required", "onBlur"]))
              : _createCommentVNode("", true),
            (_ctx.$can(_unref(Permission).ASSIGN_LEVEL_TO_USER))
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("label", _hoisted_5, [
                    _createTextVNode(_toDisplayString(_ctx.$t("user.labels.levels")) + " ", 1),
                    _cache[15] || (_cache[15] = _createElementVNode("span", { class: "text-xs" }, "*", -1))
                  ]),
                  _createVNode(_unref(UiMultiselect), {
                    name: "levels",
                    id: "levels",
                    modelValue: formState.levels,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((formState.levels) = $event)),
                    options: levels.value,
                    primitive: true,
                    multiple: true,
                    "can-clear": true,
                    errors: _unref(v$).levels?.$errors,
                    label: "name",
                    "value-prop": "id"
                  }, null, 8, ["modelValue", "options", "errors"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.$can(_unref(Permission).ASSIGN_ROLE_TO_USER))
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("label", _hoisted_7, [
                    _createTextVNode(_toDisplayString(_ctx.$t("user.labels.roles")) + " ", 1),
                    _cache[16] || (_cache[16] = _createElementVNode("span", { class: "text-xs" }, "*", -1))
                  ]),
                  _createVNode(_unref(UiMultiselect), {
                    name: "roles",
                    id: "roles",
                    modelValue: formState.roles,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((formState.roles) = $event)),
                    options: roles.value,
                    primitive: true,
                    multiple: true,
                    "can-clear": true,
                    errors: _unref(v$).roles?.$errors,
                    label: "name",
                    "value-prop": "id"
                  }, null, 8, ["modelValue", "options", "errors"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", _hoisted_9, [
                _createTextVNode(_toDisplayString(_ctx.$t("user.labels.standardLevel")) + " ", 1),
                _cache[17] || (_cache[17] = _createElementVNode("span", { class: "text-xs" }, "*", -1))
              ]),
              _createVNode(_unref(UiMultiselect), {
                name: "levels",
                id: "levels",
                modelValue: formState.standardLevelId,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((formState.standardLevelId) = $event)),
                options: selectedLevels.value,
                errors: _unref(v$).standardLevelId?.$errors,
                primitive: "",
                label: "name",
                "value-prop": "id"
              }, null, 8, ["modelValue", "options", "errors"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_unref(UiCheckboxInput), {
                modelValue: formState.isActive,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((formState.isActive) = $event)),
                name: "isActive",
                label: _ctx.$t('user.labels.isActive'),
                class: "h-6"
              }, null, 8, ["modelValue", "label"]),
              (_unref(levelHasMeeting))
                ? (_openBlock(), _createBlock(_unref(UiCheckboxInput), {
                    key: 0,
                    modelValue: formState.hasMeeting,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((formState.hasMeeting) = $event)),
                    name: "hasMeeting",
                    label: _ctx.$t('user.labels.hasMeeting'),
                    class: "h-6 pt-1.5"
                  }, null, 8, ["modelValue", "label"]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_unref(UiPanel), null, {
        content: _withCtx(() => [
          _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.$t("user.labels.devices.title")), 1),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(UserFormDevice, {
              label: _ctx.$t('user.labels.devices.mobile'),
              modelValue: formState.devices.mobile,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((formState.devices.mobile) = $event))
            }, {
              icon: _withCtx(() => [
                _createVNode(_unref(DeviceMobileIcon), { class: "h-20 w-20 mt-6" })
              ]),
              _: 1
            }, 8, ["label", "modelValue"]),
            _createVNode(UserFormDevice, {
              label: _ctx.$t('user.labels.devices.desktop'),
              modelValue: formState.devices.desktop,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((formState.devices.desktop) = $event))
            }, {
              icon: _withCtx(() => [
                _createVNode(_unref(DesktopComputerIcon), { class: "h-20 w-20 mt-6" })
              ]),
              _: 1
            }, 8, ["label", "modelValue"]),
            _createVNode(UserFormDevice, {
              label: _ctx.$t('user.labels.devices.web'),
              modelValue: formState.devices.web,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((formState.devices.web) = $event))
            }, {
              icon: _withCtx(() => [
                _createVNode(SVGChromeLogo, { class: "h-20 w-20 mt-6" })
              ]),
              _: 1
            }, 8, ["label", "modelValue"]),
            _createVNode(UserFormDevice, {
              label: _ctx.$t('user.labels.devices.external'),
              modelValue: formState.devices.external,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((formState.devices.external) = $event))
            }, _createSlots({
              icon: _withCtx(() => [
                _createVNode(SVGDeviceExternal, { class: "h-20 w-20 mt-6" })
              ]),
              _: 2
            }, [
              (externalConfig.value)
                ? {
                    name: "extra-info",
                    fn: _withCtx(() => [
                      _createElementVNode("div", _hoisted_13, [
                        _cache[21] || (_cache[21] = _createElementVNode("h3", { class: "font-semibold text-base mb-1" }, " Configurações do PABX ", -1)),
                        _createElementVNode("p", null, [
                          _cache[18] || (_cache[18] = _createElementVNode("span", { class: "font-semibold" }, "Hostname:", -1)),
                          _createTextVNode(" " + _toDisplayString(externalConfig.value.hostname), 1)
                        ]),
                        _createElementVNode("p", null, [
                          _cache[19] || (_cache[19] = _createElementVNode("span", { class: "font-semibold" }, "Username:", -1)),
                          _createTextVNode(" " + _toDisplayString(externalConfig.value.sipUser), 1)
                        ]),
                        _createElementVNode("p", null, [
                          _cache[20] || (_cache[20] = _createElementVNode("span", { class: "font-semibold" }, "Password:", -1)),
                          _createTextVNode(" " + _toDisplayString(externalConfig.value.sipPassword), 1)
                        ])
                      ])
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["label", "modelValue"])
          ]),
          (formState.number)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode(UserFormExtension, {
                  modelValue: formState.extension,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((formState.extension) = $event)),
                  devices: formState.devices
                }, null, 8, ["modelValue", "devices"]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("user.labels.number")), 1),
                  _createVNode(_unref(UiMultiselect), {
                    name: `number`,
                    modelValue: formState.number.numberId,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((formState.number.numberId) = $event)),
                    placeholder: _ctx.$t('core.random'),
                    options: getLevelNumbers(formState.standardLevelId),
                    primitive: true,
                    multiple: false,
                    "can-clear": true,
                    disabled: noDevice.value,
                    label: "number",
                    "value-prop": "id"
                  }, null, 8, ["modelValue", "placeholder", "options", "disabled"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_unref(UiButton), {
          variant: "white",
          size: "xl",
          "text-variant": "gray-500",
          to: { name: 'ListUser' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1)
          ]),
          _: 1
        }),
        (
            (props.action == _unref(FormAction).CREATE &&
              _ctx.$can(_unref(Permission).CREATE_USER)) ||
            (props.action == _unref(FormAction).EDIT && _ctx.$can(_unref(Permission).EDIT_USER))
          )
          ? (_openBlock(), _createBlock(_unref(UiButton), {
              key: 0,
              type: "submit",
              variant: "primary",
              size: "xl",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ], 32),
    _createVNode(_unref(UiCheckDirtyBeforeRouteLeave), {
      dirty: _unref(v$).$anyDirty
    }, null, 8, ["dirty"])
  ]))
}
}

})