import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "text-sm text-gray-500" }
const _hoisted_3 = { class: "block truncate" }
const _hoisted_4 = { class: "block truncate" }
const _hoisted_5 = { class: "flex items-center text-sm" }
const _hoisted_6 = { class: "text-md" }
const _hoisted_7 = { class: "flex items-center gap-1.5 text-gray-600 text-sm" }
const _hoisted_8 = { class: "whitespace-nowrap" }
const _hoisted_9 = { class: "text-gray-500 text-sm" }
const _hoisted_10 = { class: "block" }
const _hoisted_11 = { class: "text-gray-500 text-sm" }
const _hoisted_12 = { class: "block" }
const _hoisted_13 = { class: "text-gray-500 text-sm" }
const _hoisted_14 = { class: "block" }
const _hoisted_15 = { class: "text-gray-500 text-sm" }
const _hoisted_16 = { class: "block" }
const _hoisted_17 = { class: "text-gray-500 text-sm" }
const _hoisted_18 = { class: "block" }

import moment from "moment";
import { PhoneIcon } from "@heroicons/vue/solid";
import { computed, ComputedRef } from "vue";
import {
  CallType,
  ICall,
  CallStatusClassColor,
  CallPlayerGetters,
  Permission,
} from "@/definitions";
import { can } from "@/utils/auth";
import { UiPanel } from "@/components";
import { CallAudioDownloadButton } from "@/views/pages/call/components";
import {
  PhoneIncomingIcon,
  PhoneOutgoingIcon,
  ClockIcon,
} from "@heroicons/vue/outline";

import { formatSecToTime } from "@/utils/datetimeCommon";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallDetails',
  setup(__props) {

const call: ComputedRef<ICall> = computed(
  () => store.getters[CallPlayerGetters.CALL],
);

const duration = computed(() =>
  moment(call.value?.endedAt).diff(moment(call.value?.answeredAt), "seconds"),
);

const showCallAudioDownloadButton = computed(
  () => call.value.audioFilePath && can(Permission.DOWNLOAD_CALL_AUDIO),
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiPanel), { class: "mt-0" }, {
    content: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(`grid grid-cols-2 px-4 sm:px-0 sm:grid-cols-${showCallAudioDownloadButton.value ? '9' : '8'} gap-4 sm:gap-0 items-center`)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_unref(ClockIcon), {
            class: "h-5 w-5 text-gray-400 mr-1",
            "aria-hidden": "true"
          }),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(moment)(call.value.startedAt).format("DD/MM/YYYY")), 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(moment)(call.value.startedAt).format("HH:mm:ss")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (call.value.type === _unref(CallType).INBOUND)
            ? (_openBlock(), _createBlock(_unref(PhoneIncomingIcon), {
                key: 0,
                class: "h-5 w-5 text-cyan-500 mr-1",
                "aria-hidden": "true"
              }))
            : (call.value.type === _unref(CallType).OUTBOUND)
              ? (_openBlock(), _createBlock(_unref(PhoneOutgoingIcon), {
                  key: 1,
                  class: "h-5 w-5 text-orange-500 mr-1",
                  "aria-hidden": "true"
                }))
              : (call.value.type === _unref(CallType).INTERNAL)
                ? (_openBlock(), _createBlock(_unref(PhoneIcon), {
                    key: 2,
                    class: "h-5 w-5 text-gray-500 mr-1",
                    "aria-hidden": "true"
                  }))
                : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t(`call.type.${call.value.type}`)), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", {
            class: _normalizeClass(["h-2.5 w-2.5 rounded-full", _unref(CallStatusClassColor)[call.value.status]])
          }, null, 2),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t(`call.status.${call.value.status}`)), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("call.view.origin")), 1),
          _createElementVNode("span", null, _toDisplayString(call.value.events[0].sourceNumber), 1)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("call.view.mask")), 1),
          _cache[0] || (_cache[0] = _createElementVNode("span", null, "-", -1))
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("call.view.destination")), 1),
          _createElementVNode("span", null, _toDisplayString(call.value.destinationNumber), 1)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("call.view.duration")), 1),
          _createElementVNode("span", null, _toDisplayString(_unref(formatSecToTime)(duration.value)), 1)
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t("call.view.media")), 1),
          _createElementVNode("span", null, _toDisplayString(call.value.media), 1)
        ]),
        (showCallAudioDownloadButton.value)
          ? (_openBlock(), _createBlock(_unref(CallAudioDownloadButton), {
              key: 0,
              callId: call.value.id
            }, null, 8, ["callId"]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }))
}
}

})