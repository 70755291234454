import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { DownloadIcon } from "@heroicons/vue/solid";
import { UiButton } from "@/components/index";
import { VaultService } from "@/services";
import Analytics from "@/utils/analytics";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallAudioDownloadButton',
  props: {
  callId: {
    type: Number,
    required: true,
  },
},
  setup(__props) {



async function downloadCallAudio(callId: number): Promise<void> {
  try {
    if (!callId) return;

    Analytics.trackingAction("CallAudioDownload").then();

    // Fetch audio data from VaultService
    const { data } = await VaultService.getCallAudio(callId);

    // Create blob and object URL
    const blob = new Blob([data], { type: "audio/mpeg" });
    const url = URL.createObjectURL(blob);

    // Create download link
    const link = document.createElement("a");
    link.href = url;
    link.download = `${callId}.mp3`;
    document.body.appendChild(link);
    link.click();

    // Cleanup
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading call audio:", error);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiButton), {
    variant: "primary",
    "text-variant": "white",
    themed: "",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (downloadCallAudio(__props.callId)))
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(DownloadIcon), {
        class: "-ml-1 mr-2 h-5 w-5",
        "aria-hidden": "true"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t("core.actions.Download")), 1)
    ]),
    _: 1
  }))
}
}

})